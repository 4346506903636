import { getDeployOptions } from "./environment";

/**
 * Application configuration wrapper
 */
export default class Config {
    /**
     * Return stripe key
     */
    public static get stripeKey(): string {
        const OPTIONS = getDeployOptions();
        return OPTIONS.stripeKey;
    }

    public static get quickbookClientId(): string {
        const OPTIONS = getDeployOptions();
        return OPTIONS.quickbookClientId;
    }

    public static get quickbookRedirectUri(): string {
        const OPTIONS = getDeployOptions();
        return OPTIONS.quickbookRedirectUri;
    }

    public static get fileStoreUrl(): string {
        const OPTIONS = getDeployOptions();
        return OPTIONS.fileStoreUrl;
    }

    public static get lambdaApiUrl(): string {
        const OPTIONS = getDeployOptions();
        return OPTIONS.lambdaApiUrl;
    }

    public static get lambdaApiKey(): string {
        const OPTIONS = getDeployOptions();
        return OPTIONS.lambdaApiKey;
    }
}
