type FabricationPricesObject = {
  case_for_base?: boolean;
  case_for_lining_fabrication?: boolean
  case_for_lining_material?: boolean
  case_for_interlining_fabrication_and_material?: boolean
  case_for_extra_long_shades?: boolean
  case_for_extra_wide_shades?: boolean
  case_for_trim_pre_made?: boolean
  case_for_trim_from_fabric?: boolean
  case_for_other_embellishments?: boolean
  case_for_front_valance?: boolean
  case_for_back_valance?: boolean
  case_for_side_flaps?: boolean
  case_for_specialty_fabrics_surcharge?: boolean
}

const fabrication_type_prices_show = (arr: any) => {
  if (!arr || !Array.isArray(arr)) {
    const emptyObj: FabricationPricesObject = {}
    return emptyObj
  }

  const obj: FabricationPricesObject = arr.reduce((acc: any, obj: any) => {
    if (obj && obj.label) {
      const check_case_name = obj.checkCaseName
      const case_value = obj.value
      const { valanceAndReturns = {}} = obj.romanShadeJson || {}
      const { frontValance = -1, backValance = -1, sides = '' } = valanceAndReturns || {}

      switch(obj.label){
        case 'Base':
          acc[check_case_name] = true
          break;
        case 'Lining Fabrication':
          acc[check_case_name] = obj.arrayValue && obj.arrayValue.length > 0 
            ? obj.arrayValue.find((i: any) => i.name === 'Lining' && i.active) ? true : false
            : false
          break;
        case 'Lining Material':
          acc[check_case_name] = obj.arrayValue && obj.arrayValue.length > 0 
            ? obj.arrayValue.find((i: any) => i.name === 'Lining' && i.active) || backValance === 1 ? true : false
            : false
          break;
        case 'Interlining Fabrication':
        case 'Interlining Material':
          acc[check_case_name] = obj.arrayValue && obj.arrayValue.length > 0 
            ? obj.arrayValue.find((i: any) => i.name === 'Interlining' && i.active) ? true : false
            : false
          break;
        case 'Extra Long Shades':
          let check_case_extra_long_shades = true
          if (case_value === undefined || case_value === null || case_value === '' || Number(case_value) <= 0) {
            check_case_extra_long_shades = false
          }
          acc[check_case_name] = check_case_extra_long_shades
          break;
        case 'Extra Wide Shades':
          let check_case_extra_wide_shades = true
          if (case_value === undefined || case_value === null || case_value === '' || Number(case_value) <= 0) {
            check_case_extra_wide_shades = false
          }
          acc[check_case_name] = check_case_extra_wide_shades
          break;
        case 'Trim (Pre-made)':
          acc[check_case_name] = obj.arrayValue && obj.arrayValue.length > 0 
            ? obj.arrayValue.some((i: any) => i.draperyOrderFabric && i.subType === 0) ? true : false
            : false
          break;
        case 'Trim (From Fabric)':
          acc[check_case_name] = obj.arrayValue && obj.arrayValue.length > 0 
          ? obj.arrayValue.some((i: any) => i.draperyOrderFabric && i.subType === 1) ? true : false
          : false
          break;
        case 'Other Embellishment':
          acc[check_case_name] = obj.arrayValue && obj.arrayValue.length > 0 
          ? obj.arrayValue.some((i: any) => i.draperyOrderFabric) ? true : false
          : false
          break;
        case 'Front Valance':
          acc[check_case_name] = frontValance !== 1 ? false : true
          break;
        case 'Back Valance':
          acc[check_case_name] = backValance !== 1 ? false : true
          break;
        case 'Side Flaps':
          acc[check_case_name] = sides !== 'side-flaps' ? false : true
          break;
        case 'Specialty Fabrics Surcharge':
          let check_case_speciality_fabrics_surcharge = true
          if (case_value === undefined || case_value === null || case_value === '' || Number(case_value) <= 0) {
            check_case_speciality_fabrics_surcharge = false
          }
          acc[check_case_name] = check_case_speciality_fabrics_surcharge
          break;
        default:
          const emptyObj: FabricationPricesObject = {}
          return emptyObj
      }
    }
    return acc
  }, {})

  return obj
}

export {
  fabrication_type_prices_show,
}