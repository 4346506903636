/**
 * Deployment time options. Being populated by docker init script. Value will be differ depends on current environment
 * Need it because application is being built on CI server and we need to deploy to various environments (dev, prod, staging)
 */
export interface DeployOptions {
    apiUrl: string;
    restApiUrl: string;
    apiScheme: "http" | "https";
    allowDevTools: boolean;
    allowLogger: boolean;
    stripeKey: string;
    quickbookClientId: string;
    quickbookRedirectUri: string;
    fileStoreUrl: string;
    lambdaApiUrl: string;
    lambdaApiKey: string;
}

// Compile time environment
interface CompileEnvironment {
    NODE_ENV: string;
    APPLICATION_VERSION: string;
}

/**
 * Return environment
 */
export function getEnv(): CompileEnvironment {
    return process.env as any;
}

(window as any).deployOptions = {};

/**
 * Return deploy options
 */
export function getDeployOptions(): DeployOptions {
    return (window as any).deployOptions as DeployOptions;
}

export function setDeployOptions(opts: DeployOptions): void {
    (window as any).deployOptions = opts;
}
