import Bind from "lodash-decorators/bind";
import React, { Component, createRef, RefObject } from "react";
import { RGBColor } from "react-color";
import { renderToString } from "react-dom/server";
import { DraperyModule } from "../../../redux";
import styled, { theme } from "../../../theme";
import { DraperyHeader } from "../../calculator/styles";
import { BlankSvg, FivePanelsSvg, FourPanelsSvg, LeftSvg, NoValanceNoReturnsWire, NoValanceReturnsWire, NoValanceWrappedReturns, PairSvg, poppinsFont, RightSvg, SingleSvg, ThreePanelsSvg, ValanceNoReturnsWire, ValanceReturnsWire } from "./bk_svgs";
import Board, { viewBox } from "./board/board";
import Toolbar from "./toolbar/toolbar";

interface ContainerProps {
    uiWidth: number;
}

const Container = styled.div<ContainerProps>`
    position: relative;
    width: ${props => props.uiWidth ? `${props.uiWidth}px` : `70vh`};
    height: inherit;
    padding-top: ${props => props.uiWidth ? `${viewBox.height * props.uiWidth / viewBox.width}px` : `${viewBox.height * 70 / viewBox.width}vh`};
    border: 1px solid ${theme.colors.grayRGBA};
    @media screen and (max-width:992px){
        width:100%;
    }
`;

const RatioContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 10px;
`;

const HelpText = styled.div`
    margin-left: 10px;
    align-self: flex-end;
    padding-bottom: 8px;
`;

interface Props {
    shapes?: DraperyModule.Shape[];
    category: number;
    panelType?: string;
    panelJson: DraperyModule.DraperyOrderDesignPanelJson;
    rodWidth?: number;
    finishedLengthOfPanels: number;
    disabled: boolean;
    valanceForDrawing?: string;
    onShapeChanged(shapes: DraperyModule.Shape[]): void;
    orderType?: string;
    romanShadeJson?: any;
}

interface State {
    tool: "line" | "pen" | "text" | "selection" | "rect" | "circle";
    arrow: boolean;
    lineWeight: number;
    lineType: "solid" | "dashed";
    filledColor: RGBColor;
    strokeColor: RGBColor;
    fontSize: number;
    selected: boolean;
    width: number;
}

class Drawing extends Component<Props, State> {
    public state: State = {
        tool: "line",
        arrow: false,
        lineWeight: 3,
        lineType: "solid",
        filledColor: {r: 255, g: 255, b: 255, a: 1},
        strokeColor: {r: 59, g: 151, b: 177, a: 1},
        fontSize: 18,
        selected: false,
        width: 0,
    };

    private boardRef: RefObject<Board> = createRef();

    public constructor(props: Props) {
        super(props);
    }

    public componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        setTimeout(() => {
            this.handleResize();
        }, 0);
    }

    public componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    public render() {
        return (
            <>
                {
                this.props.orderType !== 'roman-shade' && 
                (
                    <HeaderContainer>
                        <DraperyHeader noMarginBottom noMarginTop>DRAWING</DraperyHeader>
                        <HelpText>
                            {!this.props.disabled && this.state.tool === "selection" && !this.state.selected ? `Select an object to move.` : `Draw Embellishments, if any, on picture below.`}
                        </HelpText>
                    </HeaderContainer>
                )
                }

                {this.props.orderType === 'custom-order' ? (
                     <div style={{ width: this.state.width ? this.state.width : '70vh'}}>
                        <Container
                            uiWidth={this.state.width}
                        >
                            <RatioContainer>
                                {this.renderBackground()}
                                <Board
                                    ref={this.boardRef}
                                    category={this.props.category}
                                    panelType={this.props.panelType}
                                    panelJson={this.props.panelJson}
                                    rodWidth={this.props.rodWidth}
                                    finishedLengthOfPanels={this.props.finishedLengthOfPanels}
                                    shapes={this.props.shapes}
                                    disabled={this.props.disabled}
                                    tool={this.state.tool}
                                    arrow={this.state.arrow}
                                    lineWeight={this.state.lineWeight}
                                    lineType={this.state.lineType}
                                    filledColor={this.state.filledColor}
                                    strokeColor={this.state.strokeColor}
                                    fontSize={this.state.fontSize}
                                    onShapeChanged={this.props.onShapeChanged}
                                    onShapeSelected={this.handleShapeSelected}
                                />
                            </RatioContainer>
                        </Container>
                        {!this.props.disabled &&
                            <Toolbar
                                uiWidth={this.state.width}
                                tool={this.state.tool}
                                arrow={this.state.arrow}
                                lineWeight={this.state.lineWeight}
                                lineType={this.state.lineType}
                                filledColor={this.state.filledColor}
                                strokeColor={this.state.strokeColor}
                                fontSize={this.state.fontSize}
                                selected={this.state.selected}
                                handleToolbar={this.handleToolbar}
                                handleLineWeight={this.handleLineWeight}
                                handleColor={this.handleColor}
                                handleClear={this.handleClear}
                                orderType={this.props.orderType}
                            />
                        }
                    </div>
                ) : (
                    <>
                        <Container
                            uiWidth={this.state.width}
                        >
                            <RatioContainer>
                                {this.renderBackground()}
                                <Board
                                    ref={this.boardRef}
                                    category={this.props.category}
                                    panelType={this.props.panelType}
                                    panelJson={this.props.panelJson}
                                    rodWidth={this.props.rodWidth}
                                    finishedLengthOfPanels={this.props.finishedLengthOfPanels}
                                    shapes={this.props.shapes}
                                    disabled={this.props.disabled}
                                    tool={this.state.tool}
                                    arrow={this.state.arrow}
                                    lineWeight={this.state.lineWeight}
                                    lineType={this.state.lineType}
                                    filledColor={this.state.filledColor}
                                    strokeColor={this.state.strokeColor}
                                    fontSize={this.state.fontSize}
                                    onShapeChanged={this.props.onShapeChanged}
                                    onShapeSelected={this.handleShapeSelected}
                                />
                            </RatioContainer>
                        </Container>
                        {!this.props.disabled &&
                            <Toolbar
                                uiWidth={this.state.width}
                                tool={this.state.tool}
                                arrow={this.state.arrow}
                                lineWeight={this.state.lineWeight}
                                lineType={this.state.lineType}
                                filledColor={this.state.filledColor}
                                strokeColor={this.state.strokeColor}
                                fontSize={this.state.fontSize}
                                selected={this.state.selected}
                                handleToolbar={this.handleToolbar}
                                handleLineWeight={this.handleLineWeight}
                                handleColor={this.handleColor}
                                handleClear={this.handleClear}
                            />
                        }
                    </>
                )}
            </>
        );
    }

    public renderBackground(isPdf?: boolean) {
        const { panelJson } = this.props;
        if (this.props.orderType === 'roman-shade') {
            switch (this.props.valanceForDrawing) {
                case 'valance-no-returns-wire':
                    return (
                        <ValanceNoReturnsWire
                            rodWidth={this.props.rodWidth}
                            finishedLengthOfPanels={this.props.finishedLengthOfPanels}
                            panelWidth={panelJson.pannel1Width}
                            isPdf={isPdf}
                            hideBorder
                            romanShadeJson={this.props.romanShadeJson}
                        />
                    );
                case 'valance-returns-wire':
                    return (
                        <ValanceReturnsWire
                            rodWidth={this.props.rodWidth}
                            finishedLengthOfPanels={this.props.finishedLengthOfPanels}
                            panelWidth={panelJson.pannel1Width}
                            isPdf={isPdf}
                            hideBorder
                            romanShadeJson={this.props.romanShadeJson}
                        />
                    );
                case 'no-valance-returns-wire':
                    return (
                        <NoValanceReturnsWire
                            category={this.props.category}
                            rodWidth={this.props.rodWidth}
                            finishedLengthOfPanels={this.props.finishedLengthOfPanels}
                            panelWidth={panelJson.pannel1Width}
                            isPdf={isPdf}
                            hideBorder
                            romanShadeJson={this.props.romanShadeJson}
                        />
                    );
                case 'no-valance-wrapped-returns':
                    return (
                        <NoValanceWrappedReturns
                            category={this.props.category}
                            rodWidth={this.props.rodWidth}
                            finishedLengthOfPanels={this.props.finishedLengthOfPanels}
                            panelWidth={panelJson.pannel1Width}
                            isPdf={isPdf}
                            hideBorder
                            romanShadeJson={this.props.romanShadeJson}
                        />
                    )
                default:
                    return (
                        <NoValanceNoReturnsWire
                            category={-1}
                            rodWidth={this.props.rodWidth}
                            finishedLengthOfPanels={this.props.finishedLengthOfPanels}
                            panelWidth={panelJson.pannel1Width}
                            isPdf={isPdf}
                            hideBorder
                            romanShadeJson={this.props.romanShadeJson}
                        />
                    );
            }
        }
        
        switch (this.props.panelType) {
            case "center":
                return (
                    <SingleSvg
                        category={this.props.category}
                        rodWidth={this.props.rodWidth}
                        finishedLengthOfPanels={this.props.finishedLengthOfPanels}
                        panelWidth={panelJson.pannel1Width}
                        isPdf={isPdf}
                        hideBorder
                    />
                );
            case "left":
                return (
                    <LeftSvg
                        category={this.props.category}
                        rodWidth={this.props.rodWidth}
                        finishedLengthOfPanels={this.props.finishedLengthOfPanels}
                        panelWidth={panelJson.pannel1Width}
                        isPdf={isPdf}
                        hideBorder
                    />
                );
            case "right":
                return (
                    <RightSvg
                        category={this.props.category}
                        rodWidth={this.props.rodWidth}
                        finishedLengthOfPanels={this.props.finishedLengthOfPanels}
                        panelWidth={panelJson.pannel1Width}
                        isPdf={isPdf}
                        hideBorder
                    />
                );
            case "pair":
                return (
                    <PairSvg
                        category={this.props.category}
                        rodWidth={this.props.rodWidth}
                        finishedLengthOfPanels={this.props.finishedLengthOfPanels}
                        panelWidthLeft={panelJson.pannel1Width}
                        panelWidthRight={panelJson.pannel2Width}
                        isPdf={isPdf}
                        hideBorder
                    />
                );
            case "three-panels":
                return (
                    <ThreePanelsSvg
                        category={this.props.category}
                        rodWidth={this.props.rodWidth}
                        finishedLengthOfPanels={this.props.finishedLengthOfPanels}
                        panelWidthLeft={panelJson.pannel1Width}
                        panelWidthMiddle={panelJson.pannel2Width}
                        panelWidthRight={panelJson.pannel3Width}
                        isPdf={isPdf}
                        hideBorder
                    />
                );
            case "four-panels":
                return (
                    <FourPanelsSvg
                        category={this.props.category}
                        rodWidth={this.props.rodWidth}
                        finishedLengthOfPanels={this.props.finishedLengthOfPanels}
                        panelWidth1={panelJson.pannel1Width}
                        panelWidth2={panelJson.pannel2Width}
                        panelWidth3={panelJson.pannel3Width}
                        panelWidth4={panelJson.pannel4Width}
                        isPdf={isPdf}
                        hideBorder
                    />
                );
            case "five-panels":
                return (
                    <FivePanelsSvg
                        category={this.props.category}
                        rodWidth={this.props.rodWidth}
                        finishedLengthOfPanels={this.props.finishedLengthOfPanels}
                        panelWidth1={panelJson.pannel1Width}
                        panelWidth2={panelJson.pannel2Width}
                        panelWidth3={panelJson.pannel3Width}
                        panelWidth4={panelJson.pannel4Width}
                        panelWidth5={panelJson.pannel5Width}
                        isPdf={isPdf}
                        hideBorder
                    />
                );
            default:
                return (
                    <BlankSvg
                        category={-1}
                        hideBorder
                    />
                );
        }
    }

    @Bind()
    public async convertToImage(): Promise<Blob | null> {
        if (this.boardRef.current) {
            try {
                const boardUrl = this.boardRef.current.createImageUrl();
                if (boardUrl === null) {
                    throw new Error("Unable to get the drawing image url");
                }
                const bkUrl = this.createBkImageUrl();
                // const bkBase64 = this.createBkImageBase64();

                const canvas = document.createElement("canvas");
                canvas.width = viewBox.width * 3;
                canvas.height = viewBox.height * 3;
                const context = canvas.getContext("2d");
                if (!context) {
                    throw new Error("Unable to get the canvas context");
                }

                await this.drawImageOnContext(context, bkUrl, canvas.width, canvas.height);
                await this.drawImageOnContext(context, boardUrl, canvas.width, canvas.height);

                // convert to image
                const convertedBlob = await new Promise<Blob>((res, rej) => {
                    canvas.toBlob(blob => {
                        if (!blob) {
                            rej(new Error("Unable to crop the image"));
                        } else {
                            res(blob);
                        }
                    }, "image/png", 1);
                });

                return convertedBlob;
            } catch (e) {
                return null;
            }
        } else {
            return null;
        }
    }

    @Bind()
    public handleLineWeight(lineWeight: number, lineType: "solid" | "dashed") {
        if (this.boardRef.current) {
            this.boardRef.current.handleLineWeightForSelection(lineWeight, lineType);
        }
        this.setState({lineWeight, lineType});
    }

    @Bind()
    public handleColor(filledColor: RGBColor, strokeColor: RGBColor) {
        if (this.boardRef.current) {
            this.boardRef.current.handleColorForSelection(filledColor, strokeColor);
        }
        this.setState({filledColor, strokeColor});
    }

    @Bind()
    public handleClear() {
        if (this.state.selected) { return; }
        if (this.boardRef.current) {
            this.boardRef.current.handleClear();
        }
    }

    @Bind()
    private handleToolbar(type: "line" | "pen" | "arrow" | "text" | "selection" | "rect" | "circle") {
        if (this.state.selected) { return; }
        if (type === "line") {
            this.setState({tool: "line", arrow: false});
        } else if (type === "arrow") {
            this.setState({tool: "line", arrow: true});
        } else {
            this.setState({tool: type, arrow: false});
        }
    }

    @Bind()
    private handleShapeSelected(selected: boolean) {
        this.setState({ selected });
    }

    @Bind()
    private createBkImageUrl(): string {
        const bkSvg = renderToString(this.renderBackground(true)).replace("<defs></defs>", poppinsFont());
        const bkBlob = new Blob([bkSvg], {type: "image/svg+xml"});
        const bkUrl = URL.createObjectURL(bkBlob);
        return bkUrl;
    }

    // @Bind()
    // private createBkImageBase64(): string {
    //     const bkSvg = renderToString(this.renderBackground(true));
    //     const base64 = "data:image/svg+xml;base64," + window.btoa(bkSvg);
    //     return base64;
    // }

    private async drawImageOnContext(context: CanvasRenderingContext2D, url: string, width: number, height: number) {
        const image = document.createElement("img");
        image.src = url;
        await new Promise<void>(res => {
            image.addEventListener("load", () => {
                res();
            });
        });
        context.drawImage(image, 0, 0, width, height);
        URL.revokeObjectURL(url);
    }

    private handleResize = () => {
        const e = document.getElementById("react-tabs-1");
        if (!e) {
            // If the element is not available yet, try again in the next frame
            requestAnimationFrame(this.handleResize);
        } else {
            const width = Math.min(e.offsetWidth - 20, e.offsetHeight);
            if(width < 1) {
                return
            }
            else if (width !== this.state.width) {
                this.setState({ width });
            }
        }
    }
}

export default Drawing;
