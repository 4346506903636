import axiosInstance from "./axios-instance";
import { Config } from "../utils";

export const callLambda = async (payload: any, accountId: number, fileStoreUrl: string, workOrder: any) => {
    try {
        const response = await axiosInstance.post<any>(Config.lambdaApiUrl, JSON.stringify({
            data: payload,
            accountId,
            fileStoreUrl,
            workOrder,
        }),
            {
                headers: {
                    "x-api-key": Config.lambdaApiKey,
                }

            })
        return response.data;
    } catch (error) {
        console.error("Error with call lambda: ", error);
        throw error;
    }
}