import React, { ChangeEvent, FC } from "react";
import styled from "styled-components";

interface InchesInputProps {
    id: string;
    value?: string | number;
    onChange: (params: ChangeEvent<HTMLInputElement>) => void;
    handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    isBottom?: boolean;
    className?: string;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    placeholder?: string | undefined;
    additionText?: string
    errorClass?: any;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    popUpMessage?: string;
    popUpCheckCase?: any;
}
const StyledInput = styled.input`
    ::placeholder {
        color: #C2C2C2; 
    }

    &:focus {
        outline: none !important;
        border: 1px solid ${({ theme }) => theme.colors.primary} !important;
    }

    &.errorClass{
        background: hsl(0,71.3%,96.5%);
        border-color: hsl(0,71.3%,76.2%);
        color: hsl(0,57.1%,50.8%);
    }
`;

const SingleInchInput: FC<InchesInputProps> = ({
    id,
    value,
    onChange,
    handleKeyDown,
    isBottom = false,
    className = "",
    onFocus,
    placeholder,
    additionText,
    errorClass,
    onBlur,
    popUpMessage,
    popUpCheckCase
}) => {
    return (
        <div
            className={`grid grid-cols-1 divide-x divide-[#E2E2E2] ${
                isBottom ? "" : "border-b border-[#E2E2E2]"
            } h-14`}
        >
            {/* Finished Size */}
            <div className="flex flex-row items-center justify-center p-0">
                {additionText ? (
                    <span className="mr-2">{additionText} +</span>
                ) : null}
                <div className="relative">
                    <StyledInput
                        id={id}
                        name={id}
                        value={value}
                        className={`mr-2 rounded-[8px] items-center border max-w-[50%] lg:max-w-[72px]  text-[16px] px-2 py-[10px] h-9 font-normal border-[#AAAAAA] ${errorClass && 'errorClass'}`}
                        type="number"
                        step={0.001}
                        onChange={onChange}
                        onKeyDown={handleKeyDown}
                        data-name={value}
                        onFocus={onFocus}
                        placeholder={placeholder}
                        onBlur={onBlur}
                    />
                </div>
                <p className="text-sm m-0">in.</p>
            </div>
        </div>
    );
};

export default SingleInchInput;
