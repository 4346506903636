import { Checkbox, Input, InputProps, ValidationForm } from "@ramble/ramble-ui";
import { DispatchProp, connect } from "react-redux";
import React, {
    ChangeEvent,
    FC,
    FormEvent,
    PureComponent,
    ReactNode,
    forwardRef,
} from "react";
import {
    TooltipContainer,
    TooltipPopup,
    TooltipText,
    TooltipWidePopup,
} from "../../styles";
import styled, { css, theme } from "../../../../theme";

import Bind from "lodash-decorators/bind";
import OtherDetailsRadioGroup from "./other-details-radio-group";
import { PageContent } from "../../../content/page_content";
import SelectListE from "../../../UI/select-list";
import SingleInchInput from "./single-inch-input";
import TextCell from "./text-cell";
import { allowOnlyPositiveNum } from "../../helpers";
import { getWorkroomSetting, searchWorkroomSetting, addWorkroomSetting, editWorkroomSetting } from "../../../../api/workroom-setting";

const unitList = ["yard(s)", "%"];
const defaultItemList = [
    { type: 4, name: "Roman Shade" },
];

const SettingsCheckbox = styled(Checkbox)`
    align-items: start;

    > span.label {
        border: 0 !important;
        line-height: 35px;
        color: ${theme.colors.grayRGBA};
        font-weight: 400;
    }

    > svg {
        margin-top: 7px;
    }
    ${(props) =>
        props.className === "excess-box" &&
        css`
            width: 3%;
            align-items: center;

            > svg {
                margin-top: 0 !important;
            }
        `}
`;

const WorkroomForm = styled(ValidationForm)`
    max-width: 1280px;
    padding-top: 0;
    padding-inline: 0px;

    & .perm_folds {
        &:focus {
            outline: none !important;
            border: 1px solid ${({ theme }) => theme.colors.primary} !important;
        }
    }
`;

const InputLabel = styled.div`
    margin-left: 15px;
    margin-right: 15px;
    display: inline-block;
`;
const LabeledInputE = forwardRef<
    HTMLInputElement,
    InputProps & { label?: string }
>(({ label, ...props }, ref) => (
    <>
        <Input {...props} ref={ref} />
        {label && <InputLabel>{label}</InputLabel>}
    </>
));

const SettingsLabeledInput = styled(LabeledInputE)`
    width: 80px;
    height: 36px;
    border: solid #AAAAAA 1px;
    border-radius: 8px;
    padding: 10px 8px !important;
    line-height: normal;
`;

const Welcome = styled.div`
    max-width: 1280px;
    margin-inline: auto;
`;

const SelectList = styled(SelectListE)`
    width: 140px;
    display: inline-block;
    padding-top: 0.1em !important;
    padding-bottom: 0.1em !important;

    label {
        line-height: normal;
    }
    input {
        height: 36px;
    }
`;

interface FieldProps {
    /**
     * Field label
     */
    label?: string;
    colored?: boolean;
    /**
     * True to render as subfield with margin
     */
    subField?: boolean;
    children: ReactNode;
    className?: string;
}

const FieldE: FC<FieldProps> = (props) => (
    <div className={props.className}>
        {props.label && <div className="field-label">{props.label}</div>}
        {props.children}
    </div>
);

/**
 * Single field in options
 */
const Field = styled(FieldE)`
    display: flex;
    // margin: 0 0 0 20px;
    align-items: baseline;
    color: ${theme.colors.grayRGBA};
    font-weight: 400;

    > .field-label {
        // margin-left: 40px;
        margin-right: 5px;
        display: inline-block;
        min-width: 100px;
        ${(props) =>
        props.colored &&
        css`
                color: #306f82;
            `}
    }

    ${(props) =>
        props.label === "" &&
        css`
            padding-left: 145px;
        `}

    ${(props) =>
        props.subField &&
        css`
            display: inline-block;
            margin: 5px 0 0 150px;
        `};
`;

const ExcessBox = styled.span`
    line-height: 35px;
    font-size: 18px;
    padding-left: 0.2rem;
`;

interface DefaultSettingType {
    key: number;
    label: string;
}

// tslint:disable-next-line:no-empty-interface
// export interface ConstructionSettingsOwnProps extends RouteComponentProps {}

export interface ConstructionSettingsProps {
    accountId: number;
    setIsChanged?: any;
}
interface ErrorMessagesArray {
    table: string;
    message: string;
    key: string;
    allowanceKey: string;
}
interface State {
    /**
     * does the form validate
     */
    formError: boolean;
    /**
     * What is the form error
     */
    formErrorMessage: ErrorMessagesArray[];

    defaultSettingList: DefaultSettingType[];
    currentSettingID: number;
    currentSettingName: string;

    isYardageAddOneExtra: boolean;
    isYardageAddExcess: boolean;
    addExcessValue?: number;
    addExcessUnit?: string;

    /*draperyPinInstaller?: string;*/
    fabricPurchaser?: string;
    embellishmentsPurchaser?: string;
    liningsPurchaser?: string;
    //hardwarePurchaser?: string;

    // Embellishments
    embellishmentCordingVertical?: number;
    embellishmentCordingHorizontal?: number;
    embellishmentFlangeVertical?: number;
    embellishmentFlangeHorizontal?: number;
    embellishmentTrimVertical?: number;
    embellishmentTrimHorizontal?: number;
    embellishmentBandingVertical?: number;
    embellishmentBandingHorizontal?: number;

    embellishmentCordingVerticalCafe?: number;
    embellishmentCordingHorizontalCafe?: number;
    embellishmentFlangeVerticalCafe?: number;
    embellishmentFlangeHorizontalCafe?: number;
    embellishmentTrimVerticalCafe?: number;
    embellishmentTrimHorizontalCafe?: number;
    embellishmentBandingVerticalCafe?: number;
    embellishmentBandingHorizontalCafe?: number;

    embellishmentCordingVerticalRipplefold?: number;
    embellishmentCordingHorizontalRipplefold?: number;
    embellishmentFlangeVerticalRipplefold?: number;
    embellishmentFlangeHorizontalRipplefold?: number;
    embellishmentTrimVerticalRipplefold?: number;
    embellishmentTrimHorizontalRipplefold?: number;
    embellishmentBandingVerticalRipplefold?: number;
    embellishmentBandingHorizontalRipplefold?: number;

    // Hems & Folds
    topHemFinishedSize?: number;
    topHemFabricAllowance?: number;
    bottomNoSkirtHemFinishedSize?: number;
    bottomNoSkirtHemAllowance?: number;
    bottomWithSkirtHemFinishedSize?: number;
    bottomWithSkirtHemAllowance?: number;
    bottomHemFinishedSize?: number;
    bottomHemFabricAllowance?: number;
    sideHemFinishedSize?: number;
    sideHemFabricAllowance?: number;
    foldsFinishedSize?:number;
    foldsAllowance?:number;
    permanentFoldsFunctional?:number;
    permanentFoldsFaux?:number;
    buckram?: string;

    topHemFinishedSizeCafe?: number;
    topHemFabricAllowanceCafe?: number;
    bottomHemFinishedSizeCafe?: number;
    bottomHemFabricAllowanceCafe?: number;
    sideHemFinishedSizeCafe?: number;
    sideHemFabricAllowanceCafe?: number;
    buckramCafe?: string;

    topHemFinishedSizeRipplefold?: number;
    topHemFabricAllowanceRipplefold?: number;
    bottomHemFinishedSizeRipplefold?: number;
    bottomHemFabricAllowanceRipplefold?: number;
    sideHemFinishedSizeRipplefold?: number;
    sideHemFabricAllowanceRipplefold?: number;
    buckramRipplefold?: string;

    // Valance & Returns
    frontValanceLength?: number;
    frontValanceVerticalAllowance?: number;
    frontValanceHorizontalAllowance?: number
    backValanceLength?: number;
    backValanceVerticalAllowance?:number;
    backValanceHorizontalAllowance?:number;
    sideFlapsLength?:number;
    sideFlapsVerticalAllowance?: number;
    sideFlapsHorizontalAllowance?: number;

    // Linings & Embellishments cut lengths
    liningVerticalAllowance?:number;
    interliningVerticalAllowance?:number;
    trimVerticalAllowance?:number;
    trimHorizontalAllowance?:number;

    // Overlap
    returnOverlapLeadSize?: number;
    returnOverlapReturnSize?: number;
    returnOverlapOverlapSize?: number;
    cutLengthLining?: number;
    cutLengthInterlining?: number;
    draperyFullnessValue?: number;

    mainFabric?: number;
    mainFabricCafe?: number;
    mainFabricRipplefold?: number;

    returnOverlapLeadSizeCafe?: number;
    returnOverlapReturnSizeCafe?: number;
    returnOverlapOverlapSizeCafe?: number;
    cutLengthLiningCafe?: number;
    cutLengthInterliningCafe?: number;
    draperyFullnessValueCafe?: number;

    returnOverlapLeadSizeRipplefold?: number;
    returnOverlapReturnSizeRipplefold?: number;
    returnOverlapOverlapSizeRipplefold?: number;
    cutLengthLiningRipplefold?: number;
    cutLengthInterliningRipplefold?: number;
    draperyFullnessValueRipplefold?: number;

    isChanged: boolean;
    previousKey: number | null;
}

export class ConstructionSettings extends PureComponent<
    ConstructionSettingsProps & DispatchProp,
    State
> {
    public constructor(props: ConstructionSettingsProps & DispatchProp) {
        super(props);

        this.state = {
            defaultSettingList: [],
            formError: false,
            formErrorMessage: [],
            isYardageAddOneExtra: true,
            isYardageAddExcess: false,
            addExcessUnit: "select unit",

            currentSettingID: 0,
            currentSettingName: "Please Select",
            isChanged: false,
            previousKey: null,
        };
        this.getExistingConstructionSettings().catch(() => { });
    }

    public componentDidMount() {
        document.documentElement.scrollIntoView();
    }

    handleInputFocus = () => {
        this.setState({ previousKey: null });
    };

    public render(): JSX.Element {
        return (
            <PageContent style={{marginTop:'4px', marginInline: 'auto', fontSize: '16px'}}>
                <Welcome className="mb-6">
                    <div className="w-full flex items-center">
                        <span style={{
                            color: 'rgb(59, 151, 177)', fontSize: '20px', marginBottom:'12px',
                            paddingBottom: '3px', fontWeight: '600', lineHeight: '20px'
                        }}>OPTIONAL
                            <span style={{ color: '#B0B0B0', fontSize: '16px', fontWeight: '500' }}> (Recommended)</span>
                        </span>
                    </div>
                    Add any default settings you would like to populate on Roman Shade orders automatically below. You will be asked to complete any settings left blank on every order. These can be edited on each order as needed.
                </Welcome>

                {/* FORM */}
                <WorkroomForm
                    uiOnSubmit={this.submit}
                    uiDimmerBlurring={false}
                    autoComplete="off"
                >
                    {/* HEM SECTION */}
                    <div className="border border-[#E2E2E2]">
                        <div className="bg-[#ecf6f9] h-[52px] w-full p-2 flex items-center">
                            <p className="m-0 font-medium text-[16px]">HEMS & FOLDS</p>
                        </div>
                        <div className="grid grid-cols-3 divide-x divide-[#E2E2E2]">
                            <div className="w-full col-span-1">
                                <div>
                                    <div className="text-center pt-1 text-lg h-14 w-full mb-0"></div>
                                    <p className="border-b border-[#E2E2E2] mb-0 p-2 h-14 flex items-center">
                                        Top
                                    </p>
                                    <p className="border-b border-[#E2E2E2] mb-0 p-2 h-14 flex items-center">
                                        Bottom no Skirt
                                    </p>
                                    <div className="mb-0 flex items-center">
                                        <p className="border-b border-[#E2E2E2] mb-0 p-2 h-14 flex items-center w-full">
                                            Bottom with Skirt
                                            <TooltipPopup
                                                text={
                                                    <TooltipContainer>
                                                        <TooltipText>
                                                        <span className="font-bold">Bottom skirt</span> is also commonly referred to as the bottom flap, reveal, or footer. It refers to moving the rings up in position on the back of the shade to allow the bottom of the shade material to be exposed beneath the folds when it is lifted.
                                                        </TooltipText>
                                                    </TooltipContainer>
                                                }
                                                position="bottom left"
                                                className="normal"
                                                closeOnClick
                                            />
                                        </p>
                                    </div>
                                    <p className="border-b border-[#E2E2E2] mb-0 p-2 h-14 flex items-center">
                                        Side Hems
                                    </p>
                                    <div className="mb-0 flex items-center">
                                        <p className="border-b border-[#E2E2E2] mb-0 p-2 h-14 flex items-center w-full">
                                            Folds
                                            <TooltipPopup
                                                text={
                                                    <TooltipContainer>
                                                        <TooltipText>
                                                        <span className="font-bold">Fold Finished Size</span> refers to half the vertical distance between rings. For example, if using 8” of material folded in half, the fold size is 4”. <span className="font-bold">Total Allowance</span> refers to the amount of material needed to make each fold (i.e. 8”).
                                                        </TooltipText>
                                                    </TooltipContainer>
                                                }
                                                position="bottom left"
                                                className="normal"
                                                closeOnClick
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={`w-full col-span-1`}>
                                {/* Header */}
                                <div>
                                    <div className="h-14 overflow-hidden border-[#E2E2E2]">
                                        <p className="text-center pt-1  mb-0 text-[#3B97B1] font-medium leading-5">
                                            Finished Size
                                        </p>
                                    </div>
                                </div>

                                {/* Inches input template */}
                                <div className=" h-14 flex flex-1 justify-center items-center border-b border-[#E2E2E2]">
                                    <p className="text-center">N/A</p>
                                </div>

                                <SingleInchInput
                                    id="bottomNoSkirtHemFinishedSize"
                                    value={
                                        this.state.bottomNoSkirtHemFinishedSize !==
                                            undefined
                                            ? this.state.bottomNoSkirtHemFinishedSize
                                            : ""
                                    }
                                    onFocus={this.handleInputFocus}
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    placeholder="2"
                                    errorClass={
                                        this.state.formErrorMessage && this.state.formErrorMessage.length > 0 ? this.state.formErrorMessage.find(i => i.key === 'bottomNoSkirtHemFinishedSize') : false
                                    }
                                />

                                <SingleInchInput
                                    id="bottomWithSkirtHemFinishedSize"
                                    value={
                                        this.state.bottomWithSkirtHemFinishedSize !==
                                            undefined
                                            ? this.state.bottomWithSkirtHemFinishedSize
                                            : ""
                                    }
                                    onFocus={this.handleInputFocus}
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    placeholder="5"
                                    errorClass={
                                        this.state.formErrorMessage && this.state.formErrorMessage.length > 0 ? this.state.formErrorMessage.find(i => i.key === 'bottomWithSkirtHemFinishedSize') : false
                                    }
                                />
                                <SingleInchInput
                                    id="sideHemFinishedSize"
                                    value={
                                        this.state.sideHemFinishedSize !==
                                            undefined
                                            ? this.state.sideHemFinishedSize
                                            : ""
                                    }
                                    onFocus={this.handleInputFocus}
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    placeholder="2"
                                    errorClass={
                                        this.state.formErrorMessage && this.state.formErrorMessage.length > 0 ? this.state.formErrorMessage.find(i => i.key === 'sideHemFinishedSize') : false
                                    }
                                />
                                <SingleInchInput
                                    id="foldsFinishedSize"
                                    value={
                                        this.state.foldsFinishedSize !==
                                            undefined
                                            ? this.state.foldsFinishedSize
                                            : ""
                                    }
                                    onFocus={this.handleInputFocus}
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    placeholder="4"
                                    errorClass={
                                        this.state.formErrorMessage && this.state.formErrorMessage.length > 0 ? this.state.formErrorMessage.find(i => i.key === 'foldsFinishedSize') : false
                                    }
                                />
                            </div>
                            {/* FULL LENGTH HEM */}
                            <div className={`w-full col-span-1`}>
                                {/* Header */}
                                <div>
                                    <div  className="h-14 overflow-hidden border-[#E2E2E2]">
                                        <p className="text-center pt-1  mb-0 text-[#3B97B1] font-medium leading-5">
                                            Total Allowance
                                        </p>
                                    </div>
                                </div>

                                {/* Inches input template */}
                                <SingleInchInput
                                    id="topHemFabricAllowance"
                                    value={
                                        this.state.topHemFabricAllowance !==
                                            undefined
                                            ? this.state.topHemFabricAllowance
                                            : ""
                                    }
                                    onFocus={this.handleInputFocus}
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    placeholder="3"
                                    errorClass={
                                        this.state.formErrorMessage && this.state.formErrorMessage.length > 0 ? this.state.formErrorMessage.find(i => i.allowanceKey === 'topHemFabricAllowance') : false
                                    }
                                />
                                <SingleInchInput
                                    id="bottomNoSkirtHemAllowance"
                                    value={
                                        this.state.bottomNoSkirtHemAllowance !==
                                            undefined
                                            ? this.state.bottomNoSkirtHemAllowance
                                            : ""
                                    }
                                    onFocus={this.handleInputFocus}
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    placeholder="3"
                                    errorClass={
                                        this.state.formErrorMessage && this.state.formErrorMessage.length > 0 ? this.state.formErrorMessage.find(i => i.allowanceKey === 'bottomNoSkirtHemAllowance') : false
                                    }
                                />
                                <SingleInchInput
                                    id="bottomWithSkirtHemAllowance"
                                    value={
                                        this.state.bottomWithSkirtHemAllowance !==
                                            undefined
                                            ? this.state.bottomWithSkirtHemAllowance
                                            : ""
                                    }
                                    onFocus={this.handleInputFocus}
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    placeholder="6"
                                    errorClass={
                                        this.state.formErrorMessage && this.state.formErrorMessage.length > 0 ? this.state.formErrorMessage.find(i => i.allowanceKey === 'bottomWithSkirtHemAllowance') : false
                                    }
                                />
                                <SingleInchInput
                                    id="sideHemFabricAllowance"
                                    value={
                                        this.state.sideHemFabricAllowance !==
                                            undefined
                                            ? this.state.sideHemFabricAllowance
                                            : ""
                                    }
                                    onFocus={this.handleInputFocus}
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    placeholder="4"
                                    errorClass={
                                        this.state.formErrorMessage && this.state.formErrorMessage.length > 0 ? this.state.formErrorMessage.find(i => i.allowanceKey === 'sideHemFabricAllowance') : false
                                    }
                                />
                                <div className="grid grid-cols-1 divide-x divide-black/25 border-b border-black/25 h-14">
                                    <div className="flex items-center justify-center" style={{gap:'5px'}}>
                                        <span>{this.state.foldsFinishedSize !== undefined ? `${this.state.foldsFinishedSize * 2} in.` : 'in.'}</span>
                                    </div>
                                </div>
                                {/* <SingleInchInput
                                    id="foldsAllowance"
                                    value={
                                        this.state.foldsAllowance !==
                                            undefined
                                            ? this.state.foldsAllowance
                                            : ""
                                    }
                                    onFocus={this.handleInputFocus}
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    placeholder="8"
                                /> */}
                            </div>

                        </div>
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 2fr'
                        }}>
                            <div className="flex items-center">
                                <p className=" mb-0 p-2 h-14 flex items-center">
                                    Permanent Folds
                                </p>
                            </div>

                            <div style={{ display:'grid', gridTemplateRows: 'repeat(2,1fr)', borderLeft: '1px solid #E2E2E2'}}>
                                <div className="flex items-center" style={{borderBottom:'1px solid #E2E2E2'}}>
                                    <div className="h-full flex items-center flex-wrap" style={{padding:'0px 5px', minHeight:'3.5 rem'}}>
                                        <span className="mr-2">Functional: Include</span>
                                        <input 
                                            type="number" 
                                            step={0.001} 
                                            placeholder="3" 
                                            className="perm_folds mr-2 rounded-[8px] items-center border max-w-[50%] lg:max-w-[72px]  text-[16px] px-2 py-[10px] h-9 font-normal border-[#AAAAAA]" 
                                            id='permanentFoldsFunctional'
                                            value={this.state.permanentFoldsFunctional !== undefined ? this.state.permanentFoldsFunctional : ''}
                                            onChange={this.handleInputChangeForNumber}
                                            onKeyDown={(e) => this.handleKeyDown(e, 'permanentFoldsFunctional')}
                                            onPaste={(e) => e.preventDefault()}
                                        />
                                        <span className="mr-2">permanent folds</span>
                                        <span className="italic">(relaxed style only)</span>
                                    </div>
                                </div>

                                <div>
                                    <div className="h-full flex items-center flex-wrap" style={{padding:'0px 5px', minHeight: '3.5rem'}}>
                                        <span className="mr-2">Faux: Include</span>
                                        <input 
                                            type="number" 
                                            step={0.001}
                                            placeholder="3" 
                                            className="perm_folds mr-2 rounded-[8px] items-center border max-w-[50%] lg:max-w-[72px]  text-[16px] px-2 py-[10px] h-9 font-normal border-[#AAAAAA]" 
                                            id='permanentFoldsFaux'
                                            value={this.state.permanentFoldsFaux !== undefined ? this.state.permanentFoldsFaux : ''}
                                            onChange={this.handleInputChangeForNumber}
                                            onKeyDown={(e) => this.handleKeyDown(e, 'permanentFoldsFaux')}
                                            onPaste={(e) => e.preventDefault()}
                                        />
                                        <span className="mr-2">permanent folds</span>
                                        <span className="italic">(flat & relaxed styles)</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.formError && this.state.formErrorMessage && this.state.formErrorMessage.length > 0 && this.state.formErrorMessage.filter(i => i.table === 'Hems & Folds').length > 0 && this.state.formErrorMessage.filter(i => i.table === 'Hems & Folds').map(i => {
                        return (
                            <div
                                className="border bg-red-200 border-black/25 mt-4 p-2"
                                style={{ backgroundColor: "rgb(254 202 202)" }}
                                id="errorMessage"
                            >
                                {i.message}
                            </div>
                        )
                    })}

                    {/* VALANCE & RETURNS */}
                    <div className="mt-6 border border-[#E2E2E2]">
                        <div className="bg-[#ecf6f9] h-[52px] w-full p-2 flex items-center">
                            <p className="m-0 font-medium text-[16px]">VALANCE & RETURNS</p>
                        </div>
                        <div className="grid grid-cols-4 divide-x divide-[#E2E2E2]">
                            <div className="w-full col-span-1">
                                <div>
                                    <div className="text-center pt-1 text-lg h-14 w-full mb-0"></div>
                                    <p className="border-b border-[#E2E2E2] mb-0 p-2 h-14 flex items-center">
                                        Front Valance
                                    </p>
                                    <p className="border-b border-[#E2E2E2] mb-0 p-2 h-14 flex items-center">
                                        Back Valance
                                    </p>
                                    <p className="border-[#E2E2E2] mb-0 p-2 h-14 flex items-center">
                                        Side Flaps
                                    </p>
                                </div>
                            </div>
                            <div className={`w-full col-span-1`}>
                                {/* Header */}
                                <div>
                                    <div className="h-14 overflow-hidden border-[#E2E2E2]">
                                        <p className="text-center pt-1  mb-0 text-[#3B97B1] font-medium leading-5">
                                            Length
                                        </p>
                                    </div>
                                </div>

                                {/* Single inch input template */}
                                <SingleInchInput
                                    id="frontValanceLength"
                                    value={
                                        this.state.frontValanceLength !==
                                            undefined
                                            ? this.state.frontValanceLength
                                            : ""
                                    }
                                    onFocus={this.handleInputFocus}
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    placeholder="6"
                                    errorClass={
                                        this.state.formErrorMessage && this.state.formErrorMessage.length > 0 ? this.state.formErrorMessage.find(i => i.key === 'frontValanceLength') : false
                                    }
                                />
                                <SingleInchInput
                                    id="backValanceLength"
                                    value={
                                        this.state.backValanceLength !==
                                            undefined
                                            ? this.state.backValanceLength
                                            : ""
                                    }
                                    onFocus={this.handleInputFocus}
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    placeholder="6"
                                    errorClass={
                                        this.state.formErrorMessage && this.state.formErrorMessage.length > 0 ? this.state.formErrorMessage.find(i => i.key === 'backValanceLength') : false
                                    }
                                />
                                <SingleInchInput
                                    id="sideFlapsLength"
                                    value={
                                        this.state.sideFlapsLength !==
                                            undefined
                                            ? this.state.sideFlapsLength
                                            : ""
                                    }
                                    onFocus={this.handleInputFocus}
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    placeholder="6"
                                    isBottom
                                    errorClass={
                                        this.state.formErrorMessage && this.state.formErrorMessage.length > 0 ? this.state.formErrorMessage.find(i => i.key === 'sideFlapsLength') : false
                                    }
                                />
                            </div>
                            {/* Vertical Allowance */}
                            <div className={`w-full col-span-1`}>
                                {/* Header */}
                                <div>
                                    <div className="h-14 overflow-hidden border-[#E2E2E2]">
                                        <p className="text-center pt-1  mb-0 text-[#3B97B1] font-medium leading-5">
                                            Vertical Allowance
                                        </p>
                                    </div>
                                </div>

                                {/* Single inch input template */}
                                <SingleInchInput
                                    id="frontValanceVerticalAllowance"
                                    value={
                                        this.state.frontValanceVerticalAllowance !==
                                            undefined
                                            ? this.state.frontValanceVerticalAllowance
                                            : ""
                                    }
                                    onFocus={this.handleInputFocus}
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    placeholder="6"
                                    additionText={'(Valance FL x 2)'}
                                    errorClass={
                                        this.state.formErrorMessage && this.state.formErrorMessage.length > 0 ? this.state.formErrorMessage.find(i => i.allowanceKey === 'frontValanceVerticalAllowance') : false
                                    }
                                />
                                <SingleInchInput
                                    id="backValanceVerticalAllowance"
                                    value={
                                        this.state.backValanceVerticalAllowance !==
                                            undefined
                                            ? this.state.backValanceVerticalAllowance
                                            : ""
                                    }
                                    onFocus={this.handleInputFocus}
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    placeholder="2"
                                    additionText={'(Valance FL x 2)'}
                                    errorClass={
                                        this.state.formErrorMessage && this.state.formErrorMessage.length > 0 ? this.state.formErrorMessage.find(i => i.allowanceKey === 'backValanceVerticalAllowance') : false
                                    }
                                />
                                <SingleInchInput
                                    id="sideFlapsVerticalAllowance"
                                    value={
                                        this.state.sideFlapsVerticalAllowance !==
                                            undefined
                                            ? this.state.sideFlapsVerticalAllowance
                                            : ""
                                    }
                                    onFocus={this.handleInputFocus}
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    placeholder="8"
                                    additionText={'Side Flaps FL'}
                                    isBottom
                                    errorClass={
                                        this.state.formErrorMessage && this.state.formErrorMessage.length > 0 ? this.state.formErrorMessage.find(i => i.allowanceKey === 'sideFlapsVerticalAllowance') : false
                                    }
                                />
                            </div>
                            <div className={`w-full col-span-1`}>
                                {/* Header */}
                                <div>
                                    <div  className="h-14 overflow-hidden border-[#E2E2E2]">
                                        <p className="text-center pt-1  mb-0 text-[#3B97B1] font-medium leading-5">
                                            Horizontal Allowance
                                        </p>
                                    </div>
                                </div>

                                {/* Single inch input template */}
                                <SingleInchInput
                                    id="frontValanceHorizontalAllowance"
                                    value={
                                        this.state.frontValanceHorizontalAllowance !==
                                            undefined
                                            ? this.state.frontValanceHorizontalAllowance
                                            : ""
                                    }
                                    onFocus={this.handleInputFocus}
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    placeholder="6"
                                    additionText={'Valance FW'}
                                    errorClass={
                                        this.state.formErrorMessage && this.state.formErrorMessage.length > 0 ? this.state.formErrorMessage.find(i => i.allowanceKey === 'frontValanceHorizontalAllowance') : false
                                    }
                                />
                                <SingleInchInput
                                    id="backValanceHorizontalAllowance"
                                    value={
                                        this.state.backValanceHorizontalAllowance !==
                                            undefined
                                            ? this.state.backValanceHorizontalAllowance
                                            : ""
                                    }
                                    onFocus={this.handleInputFocus}
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    placeholder="6"
                                    additionText={'Valance FW'}
                                    errorClass={
                                        this.state.formErrorMessage && this.state.formErrorMessage.length > 0 ? this.state.formErrorMessage.find(i => i.allowanceKey === 'backValanceHorizontalAllowance') : false
                                    }
                                />
                                <SingleInchInput
                                    id="sideFlapsHorizontalAllowance"
                                    value={
                                        this.state.sideFlapsHorizontalAllowance !==
                                            undefined
                                            ? this.state.sideFlapsHorizontalAllowance
                                            : ""
                                    }
                                    onFocus={this.handleInputFocus}
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    placeholder="1"
                                    additionText={'Side Flaps FW'}
                                    isBottom
                                    errorClass={
                                        this.state.formErrorMessage && this.state.formErrorMessage.length > 0 ? this.state.formErrorMessage.find(i => i.allowanceKey === 'sideFlapsHorizontalAllowance') : false
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    {this.state.formError && this.state.formErrorMessage && this.state.formErrorMessage.length > 0 && this.state.formErrorMessage.filter(i => i.table === 'Valance & Returns').length > 0 && this.state.formErrorMessage.filter(i => i.table === 'Valance & Returns').map(i => {
                        return (
                            <div
                                className="border bg-red-200 border-black/25 mt-4 p-2"
                                style={{ backgroundColor: "rgb(254 202 202)" }}
                                id="errorMessage"
                            >
                                {i.message}
                            </div>
                        )
                    })}

                    {/* MAIN FABRIC CUT LENGTHS SECTION */}
                    <div className="mt-6 border border-[#E2E2E2]">
                        <div className="bg-[#ecf6f9] h-[52px] w-full p-2 flex items-center">
                            <span className="m-0 font-medium text-[16px]">MAIN FABRIC CUT LENGTHS</span>
                        </div>
                        <div className="grid grid-cols-2 divide-x divide-[#E2E2E2] border-b border-[#E2E2E2] items-center">
                            <div>
                                <div className="h-14 flex justify-center items-center  overflow-hidden border-[#E2E2E2]">
                                        <p className="text-lg mb-0">
                                            FUNCTIONAL
                                        </p>
                                </div>
                            </div>
                            <div>
                                <div className="h-14 flex justify-center items-center  overflow-hidden border-[#E2E2E2]">
                                        <p className="text-lg mb-0">
                                            FAUX
                                        </p>
                                </div>
                            </div>
                        </div>

                        <div className="w-full grid grid-cols-4 divide-x divide-[#E2E2E2]">
                            {/* FUNCTIONAL */}
                            <div className={`col-span-1`}>
                               <div
                                    className={`h-14 mb-0 text-left p-2 border-b border-[#E2E2E2]`}
                                >
                                    <div className="flex h-full items-center justify-left">
                                        <p className="mb-0 ">Flat, no skirt</p>
                                    </div>
                                </div>
                                <div
                                    className={`h-14 mb-0 text-left p-2 border-b border-[#E2E2E2]`}
                                >
                                    <div className="flex h-full items-center justify-left">
                                        <p className="mb-0 ">Flat, with skirt</p>
                                    </div>
                                </div>
                                <div
                                    className={`h-14 mb-0 text-left p-2 border-b border-[#E2E2E2]`}
                                >
                                    <div className="flex h-full items-center justify-left">
                                        <p className="mb-0 ">Relaxed, no skirt</p>
                                    </div>
                                </div>
                                <div
                                    className={`h-14 mb-0 text-left p-2 border-b border-[#E2E2E2]`}
                                >
                                    <div className="flex h-full items-center justify-left">
                                        <p className="mb-0 ">Relaxed, with skirt</p>
                                    </div>
                                </div>
                            </div>
                            <div className={`w-full col-span-1`}>
                                <div className="relative ">
                                    <TextCell
                                        id="mainFabricFuncCutLength"
                                        text={`FL + ${(this.state.topHemFabricAllowance ||
                                            0) +
                                            (this.state
                                                .bottomNoSkirtHemAllowance || 0)
                                            } in.`}
                                    />
                                </div>
                                <div className="relative ">
                                    <TextCell
                                        id="mainFabricFuncCutLength"
                                        text={`FL + ${(this.state.topHemFabricAllowance ||
                                            0) +
                                            (this.state
                                                .bottomWithSkirtHemAllowance || 0)
                                            } in.`}
                                    />
                                </div>
                                <div className="relative ">
                                    <TextCell
                                        id="mainFabricRipplefold"
                                        text={`FL + ${(this.state.topHemFabricAllowance ||
                                            0) + ((this.state.foldsAllowance ||
                                                0) * (this.state.permanentFoldsFunctional ||
                                                    0)) +
                                            (this.state
                                                .bottomNoSkirtHemAllowance || 0)
                                            } in.`}
                                    />
                                </div>
                                <div className="relative ">
                                    <TextCell
                                        id="mainFabricRipplefold"
                                        text={`FL + ${(this.state.topHemFabricAllowance ||
                                            0) + ((this.state.foldsAllowance ||
                                                0) * (this.state.permanentFoldsFunctional ||
                                                    0)) +
                                            (this.state
                                                .bottomWithSkirtHemAllowance || 0)
                                            } in.`}
                                    />
                                </div>
                            </div>
                            {/* FAUX */}
                            <div className={`w-full col-span-1`}>
                            <div
                                    className={`h-14 mb-0 text-left p-2 border-b border-[#E2E2E2]`}
                                >
                                    <div className="flex h-full items-center justify-left">
                                        <p className="mb-0 ">Flat, no skirt</p>
                                    </div>
                                </div>
                                <div
                                    className={`h-14 mb-0 text-left p-2 border-b border-[#E2E2E2]`}
                                >
                                    <div className="flex h-full items-center justify-left">
                                        <p className="mb-0 ">Flat, with skirt</p>
                                    </div>
                                </div>
                                <div
                                    className={`h-14 mb-0 text-left p-2 border-b border-[#E2E2E2]`}
                                >
                                    <div className="flex h-full items-center justify-left">
                                        <p className="mb-0 ">Relaxed, no skirt</p>
                                    </div>
                                </div>
                                <div
                                    className={`h-14 mb-0 text-left p-2 border-b border-[#E2E2E2]`}
                                >
                                    <div className="flex h-full items-center justify-left">
                                        <p className="mb-0 ">Relaxed, with skirt</p>
                                    </div>
                                </div>
                            </div>
                            <div className={`w-full col-span-1`}>
                            <div className="relative ">
                                    <TextCell
                                        id="mainFabricFauxCutLength"
                                        text={`FL + ${(this.state.topHemFabricAllowance ||
                                            0) + ((this.state.foldsAllowance ||
                                                0) * (this.state.permanentFoldsFaux ||
                                                    0)) +
                                            (this.state
                                                .bottomNoSkirtHemAllowance || 0)
                                            } in.`}
                                    />
                                </div>
                                <div className="relative ">
                                    <TextCell
                                        id="mainFabricFauxCutLength"
                                        text={`FL + ${(this.state.topHemFabricAllowance ||
                                            0) + ((this.state.foldsAllowance ||
                                                0) * (this.state.permanentFoldsFaux ||
                                                    0)) +
                                            (this.state
                                                .bottomWithSkirtHemAllowance || 0)
                                            } in.`}
                                    />
                                </div>
                                <div className="relative ">
                                    <TextCell
                                        id="mainFabricFauxCutLength"
                                        text={`FL + ${(this.state.topHemFabricAllowance ||
                                            0) + ((this.state.foldsAllowance ||
                                                0) * (this.state.permanentFoldsFaux ||
                                                    0)) +
                                            (this.state
                                                .bottomNoSkirtHemAllowance || 0)
                                            } in.`}
                                    />
                                </div>
                                <div className="relative ">
                                    <TextCell
                                        id="mainFabricFauxCutLength"
                                        text={`FL + ${(this.state.topHemFabricAllowance ||
                                            0) + ((this.state.foldsAllowance ||
                                                0) * (this.state.permanentFoldsFaux ||
                                                    0)) +
                                            (this.state
                                                .bottomWithSkirtHemAllowance || 0)
                                            } in.`}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    {/* MAIN FABRIC ADDITIONAL ALLOWANCE SECTION */}
                    <div className="mt-6">
                        <div className="bg-[#ecf6f9] border border-[#E2E2E2] h-[52px] w-full p-2 flex items-center">
                            <p className="font-medium uppercase text-[16px]">Main Fabric Additional Allowance</p>
                        </div>
                        <div className="m-4">
                            <Field className="mb-2">
                                <SettingsCheckbox
                                    id="isYardageAddOneExtra"
                                    name="isYardageAddOneExtra"
                                    checked={this.state.isYardageAddOneExtra}
                                    onChange={this.changeCheckbox}
                                    pricingRedesign
                                >
                                    <span className="text-[16px] leading-6">
                                        Add one extra vertical repeat to the MAIN
                                        FABRIC total yardage (per work order) for
                                        placement adjustment.
                                    </span>
                                </SettingsCheckbox>
                            </Field>
                            <Field>
                                <SettingsCheckbox
                                    id="isYardageAddExcess"
                                    name="isYardageAddExcess"
                                    checked={this.state.isYardageAddExcess}
                                    onChange={this.changeCheckbox}
                                    className="excess-box"
                                    pricingRedesign
                                >
                                    {" "}
                                </SettingsCheckbox>
                                <ExcessBox style={{marginLeft: '-13px'}}>
                                    Add{" "}
                                    <SettingsLabeledInput
                                        id="addExcessValue"
                                        name="addExcessValue"
                                        value={
                                            this.state.addExcessValue !==
                                                undefined
                                                ? this.state.addExcessValue
                                                : ""
                                        }
                                        onKeyPress={this.handleKeyPress}
                                        onChange={
                                            this.handleInputChangeForNumber
                                        }
                                        onKeyDown={this.handleKeyDown}
                                        disabled={
                                            !this.state.isYardageAddExcess
                                        }
                                        uiDisabled={
                                            !this.state.isYardageAddExcess
                                        }
                                        type="number"
                                        min="0"
                                    />
                                    &nbsp;
                                    <SelectList
                                        selectList={unitList}
                                        handleClickSelect={
                                            this.handleSelectAddExcessUnit
                                        }
                                        valueSelect={this.state.addExcessUnit}
                                        flexDirection="row"
                                        name="addExcessUnit"
                                        smallPadding
                                        uiBorderColor="#3B97B1"
                                        noClearBox
                                        uiDropArrow
                                        disabled={
                                            !this.state.isYardageAddExcess
                                        }
                                        pricingRedesign
                                        style={{ width: '120px', height: '31px' }}
                                    />{" "}
                                    excess to the MAIN FABRIC total yardage (per
                                    work order).
                                </ExcessBox>
                            </Field>
                        </div>
                    </div>

                    {this.state.formError && this.state.formErrorMessage && this.state.formErrorMessage.length > 0 && this.state.formErrorMessage.filter(i => i.table === 'Main Fabric Additional Allowance').length > 0 && this.state.formErrorMessage.filter(i => i.table === 'Main Fabric Additional Allowance').map(i => {
                        return (
                            <div
                                className="border bg-red-200 border-black/25 mt-4 p-2"
                                style={{ backgroundColor: "rgb(254 202 202)" }}
                                id="errorMessage"
                            >
                                {i.message}
                            </div>
                        )
                    })}

                    {/* LININGS & EMBELLISHMENTS CUT LENGTHS */}
                    <div className="mt-6 border border-[#E2E2E2]">
                        <div className="bg-[#ecf6f9] h-[52px] w-full p-2 flex items-center">
                            <p className="m-0 font-medium text-[16px]">LININGS & EMBELLISHMENTS CUT LENGTHS</p>
                            <TooltipWidePopup
                                text={
                                    <TooltipContainer>
                                        <TooltipText>
                                            Vertical Cut Length = Finished
                                            Length (FL) + Cut Allowance
                                        </TooltipText>
                                        <TooltipText>
                                            Horizontal Cut Length = Finished
                                            Width (FW) + Cut Allowance
                                        </TooltipText>
                                    </TooltipContainer>
                                }
                                position="bottom left"
                                className="normal wide"
                                closeOnClick
                            />
                        </div>
                        <div className="grid grid-cols-3 divide-x divide-[#E2E2E2]">
                            <div className="w-full col-span-1">
                                <div>
                                    <div className="h-14 overflow-hidden border-[#E2E2E2]">
                                        <p className="text-center pt-1  mb-0 text-[#3B97B1] font-medium leading-5">
                                            LININGS
                                        </p>
                                    </div>
                                    <p className="border-b border-[#E2E2E2] mb-0 p-2 h-14 flex items-center">
                                        Lining
                                    </p>
                                    <p className="border-b border-[#E2E2E2] mb-0 p-2 h-14 flex items-center">
                                        Interlining
                                    </p>
                                </div>
                            </div>
                            {/* Vertical Allowance */}
                            <div className={`w-full col-span-1`}>
                                {/* Header */}
                                <div>
                                    <div className="h-14 overflow-hidden border-[#E2E2E2]">
                                        <p className="text-center pt-1  mb-0 text-[#3B97B1] font-medium leading-5">
                                            Vertical Allowance
                                        </p>
                                    </div>
                                </div>

                                {/* Single inch input template */}
                                <SingleInchInput
                                    id="liningVerticalAllowance"
                                    value={
                                        this.state.liningVerticalAllowance !==
                                            undefined
                                            ? this.state.liningVerticalAllowance
                                            : ""
                                    }
                                    onFocus={this.handleInputFocus}
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={(e) => this.handleKeyDown(e, 'liningVerticalAllowance')}
                                    placeholder="3"
                                    additionText={'FL'}
                                />
                                <SingleInchInput
                                    id="interliningVerticalAllowance"
                                    value={
                                        this.state.interliningVerticalAllowance !==
                                            undefined
                                            ? this.state.interliningVerticalAllowance
                                            : ""
                                    }
                                    onFocus={this.handleInputFocus}
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={(e) => this.handleKeyDown(e, 'interliningVerticalAllowance')}
                                    placeholder="3"
                                    additionText={'FL'}
                                />
                            </div>

                            {/* FULL LENGTH */}
                            <div className={`w-full col-span-1`}>
                                {/* Header */}
                                <div>
                                    <div className="h-14 overflow-hidden border-[#E2E2E2]">
                                        <p className="text-center pt-1  mb-0 text-[#3B97B1] font-medium leading-5">
                                            Horizontal Allowance
                                        </p>
                                    </div>
                                </div>

                                {/* Single inch input template */}
                                <div className=" h-14 flex flex-1 justify-center items-center border-b border-[#E2E2E2]">
                                    <p className="text-center">N/A</p>
                                </div>
                                
                                <div className=" h-14 flex flex-1 justify-center items-center border-b border-[#E2E2E2]">
                                    <p className="text-center">N/A</p>
                                </div>
                            </div>
                        </div>

                        <div className="mb-0 flex items-center w-full border-b border-[#E2E2E2]">
                            <p className="mb-0 p-2 flex items-center text-lg text-twTableHeaderText font-medium">
                                Embellishments
                            </p>
                            <TooltipPopup
                                text={
                                  <TooltipContainer>
                                    <TooltipText>
                                        <b>TRIM:</b> Strips of fabric or pre-made trim applied on top of the main fabric.
                                    </TooltipText>
                                  </TooltipContainer>
                                }
                                position="top left"
                                className="floatRight"
                                size="500"
                            />
                        </div>

                        <div className="grid grid-cols-3 divide-x divide-[#E2E2E2]">
                            <div className="w-full col-span-1">
                                <p className="mb-0 p-2 h-14 flex items-center">
                                    Trim
                                </p>
                            </div>

                            <div className="w-full col-span-1">
                                <SingleInchInput
                                    id="trimVerticalAllowance"
                                    value={
                                        this.state.trimVerticalAllowance !==
                                            undefined
                                            ? this.state.trimVerticalAllowance
                                            : ""
                                    }
                                    onFocus={this.handleInputFocus}
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    placeholder="6"
                                    additionText={'FL'}
                                    isBottom
                                />
                            </div>

                            <div className="w-full col-span-1">
                                <SingleInchInput
                                    id="trimHorizontalAllowance"
                                    value={
                                        this.state.trimHorizontalAllowance !==
                                            undefined
                                            ? this.state.trimHorizontalAllowance
                                            : ""
                                    }
                                    onFocus={this.handleInputFocus}
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    placeholder="6"
                                    additionText={'FW'}
                                    isBottom
                                />
                            </div>
                        </div>
                    </div>

                    {this.state.formError && this.state.formErrorMessage && this.state.formErrorMessage.length > 0 && this.state.formErrorMessage.filter(i => i.table === 'Linings & embellishments cut lengths').length > 0 && this.state.formErrorMessage.filter(i => i.table === 'Linings & embellishments cut lengths').map(i => {
                        return (
                            <div
                                className="border bg-red-200 border-black/25 mt-4 p-2"
                                style={{ backgroundColor: "rgb(254 202 202)" }}
                                id="errorMessage"
                            >
                                {i.message}
                            </div>
                        )
                    })}

                    <div className="mt-6">
                        <div className="border border-[#E2E2E2]  bg-[#ecf6f9] text-[#222222] h-[52px] w-full py-[16px] px-[10px] flex items-center font-medium">
                            <p className="font-medium text-[16px]">OTHER DETAILS</p>
                        </div>
                        <div>
                            <OtherDetailsRadioGroup
                                title="Fabric purchaser"
                                id="fabricPurchaser"
                                value={this.state.fabricPurchaser}
                                onChange={this.handleOtherOptionsSelect}
                            />
                            <OtherDetailsRadioGroup
                                title="Embellishments purchaser"
                                id="embellishmentsPurchaser"
                                value={this.state.embellishmentsPurchaser}
                                onChange={this.handleOtherOptionsSelect}
                            />
                            <OtherDetailsRadioGroup
                                title="Linings purchaser"
                                id="liningsPurchaser"
                                value={this.state.liningsPurchaser}
                                onChange={this.handleOtherOptionsSelect}
                            />
                        </div>
                    </div>

                    {this.state.formError && this.state.formErrorMessage && this.state.formErrorMessage.length > 0 && this.state.formErrorMessage.filter(i => i.table === '').length > 0 && this.state.formErrorMessage.filter(i => i.table === '').map(i => {
                        return (
                            <div
                                className="border bg-red-200 border-[#E2E2E2] mt-4 p-2"
                                style={{ backgroundColor: "rgb(254 202 202)" }}
                                id="errorMessage"
                            >
                                {i.message}
                            </div>
                        )
                    })}
                </WorkroomForm>
            </PageContent>
        );
    }

    private async getExistingConstructionSettings(): Promise<void> {
        const { accountId } = this.props;
        // check whether default workroom settings is already exist
        // if not create new one
        const res = await searchWorkroomSetting(accountId)
        if (res.error) {
            throw new Error(res.message);
        }

        const accountDefaultSettings = res;
        const defaultSettingList = accountDefaultSettings
            .filter(
                (accountDefaultSetting: any) =>
                    defaultItemList.filter(
                        (defaultItem) =>
                            defaultItem.type === accountDefaultSetting.type &&
                            defaultItem.name === accountDefaultSetting.name
                    ).length > 0
            )
            .map((accountDefaultSetting: any) => ({
                key: accountDefaultSetting.id,
                label: accountDefaultSetting.name,
            }));
        for (const defaultItem of defaultItemList) {
            const foundIndex = accountDefaultSettings.findIndex(
                (accountDefaultSetting: any) => {
                    return (
                        accountDefaultSetting.type === defaultItem.type &&
                        accountDefaultSetting.name.localeCompare(
                            defaultItem.name
                        ) === 0
                    );
                }
            );
            // here type = 1 means drapery order default setting

            // foundIndex = -1 means there is no default setting record in db, so we have to create new one
            if (foundIndex === -1) {
                const res1 = await addWorkroomSetting({
                    accountId,
                    type: defaultItem.type,
                    name: defaultItem.name,
                    presetIsYardageAddOneExtra: true,
                    presetIsYardageAddExcess: false,
                })
                if (res1.error) {
                    throw new Error(res1.message);
                }
                const newDefaultSetting = res1;
                defaultSettingList.push({
                    key: newDefaultSetting.id,
                    label: newDefaultSetting.name,
                });
            }
        }
        this.setState({
            defaultSettingList,
        });
        await this.onDefaultNameChanged(
            defaultSettingList[0].key,
            defaultSettingList[0].label
        );
    }

    @Bind()
    handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>, name?: string) {
        if (
            e.keyCode === 69 ||
            e.key === "e" ||
            e.keyCode === 188 ||
            e.keyCode === 187 ||
            e.keyCode === 61 ||
            e.keyCode === 107
        ) {
            e.preventDefault();
        }
        if (
            (e.keyCode === 189 ||
                e.keyCode === 173 ||
                e.keyCode === 109 ||
                e.keyCode === 48 ||
                e.keyCode === 96) &&
            this.state.previousKey === e.keyCode
        ) {
            e.preventDefault();
        }
        if (e.key === '-' && name !== 'liningVerticalAllowance' && name !== 'interliningVerticalAllowance') {
            e.preventDefault()
        }

        this.setState({ previousKey: e.keyCode });
        if (
            e.currentTarget.valueAsNumber &&
            (e.keyCode === 189 || e.keyCode === 173 || e.keyCode === 109)
        ) {
            e.preventDefault();
        }
        if (
            e.currentTarget.validity.badInput &&
            (e.keyCode === 189 || e.keyCode === 173 || e.keyCode === 109)
        ) {
            e.preventDefault();
        }
        if (
            this.state.previousKey !== null &&
            this.state.previousKey !== 189 &&
            e.currentTarget.value &&
            (e.keyCode === 173 || e.keyCode === 189 || e.keyCode === 109)
        ) {
            e.preventDefault();
        }
        if (
            this.state.previousKey !== null &&
            this.state.previousKey !== 109 &&
            e.currentTarget.value &&
            (e.keyCode === 173 || e.keyCode === 189 || e.keyCode === 109)
        ) {
            e.preventDefault();
        }
        if (
            e.currentTarget.value.includes("-") &&
            (e.keyCode === 173 || e.keyCode === 189 || e.keyCode === 109)
        ) {
            e.preventDefault();
        }
        if (
            this.state.previousKey !== null &&
            this.state.previousKey !== 173 &&
            e.currentTarget.value &&
            (e.keyCode === 173 || e.keyCode === 189 || e.keyCode === 109)
        ) {
            e.preventDefault();
        }
        if (
            (name === 'permanentFoldsFunctional' || name === 'permanentFoldsFaux') &&
            (e.keyCode === 190 || e.keyCode === 110)
        ) {
            e.preventDefault()
        }
    }

    @Bind()
    private handleInputChangeForNumber(e: ChangeEvent<HTMLInputElement>): void {
        const targetId = e.currentTarget.id;
        const targetValue = e.currentTarget.value;
        const regex = /[a-zA-Z]/;

        // if (targetValue === "--") return;
        let nValue = targetValue ? +targetValue : undefined;
        if (targetValue.match(regex)) return;
        if (
            targetValue[0] === "0" &&
            !targetValue.includes(".") &&
            targetValue.length > 1
        ) {
            return;
        }
        if (
            (targetValue &&
                targetValue.length > 2 &&
                !targetValue.includes(".") &&
                !targetValue.includes("-")) ||
            (!targetValue.includes(".") &&
                targetValue.includes("-") &&
                targetValue.length > 3)
        )
            return;
        if (
            targetValue &&
            targetValue.includes(".") &&
            targetValue.split(".")[1].length > 2
        )
            return;
        if (
            targetValue[0] === "0" &&
            !targetValue.includes(".") &&
            targetValue.length >= 2
        ) {
            return;
        }
        if (
            targetValue.length === 3 &&
            targetValue.includes("-0") &&
            !targetValue.includes(".")
        ) {
            return;
        }

        // If changing allowance, change other values that rely on it as well
        if (targetId === "sideHemFabricAllowance") {
            this.setState({
                ...this.state,
                [e.currentTarget.id]: nValue,
                embellishmentCordingHorizontal: nValue,
                embellishmentFlangeHorizontal: nValue,
                embellishmentBandingHorizontal: nValue,
            });
        } else if (targetId === "mainFabric") {
            this.setState({
                ...this.state,
                [e.currentTarget.id]: nValue,
                embellishmentBandingVertical: nValue,
            });
        } else if (targetId === "sideHemFabricAllowanceCafe") {
            this.setState({
                ...this.state,
                [e.currentTarget.id]: nValue,
                embellishmentCordingHorizontalCafe: nValue,
                embellishmentFlangeHorizontalCafe: nValue,
                embellishmentBandingHorizontalCafe: nValue,
            });
        } else if (targetId === "mainFabricCafe") {
            this.setState({
                ...this.state,
                [e.currentTarget.id]: nValue,
                embellishmentBandingVerticalCafe: nValue,
            });
        } else if (targetId === "addExcessValue") {
            const numVal = allowOnlyPositiveNum(targetValue);

            this.setState({
                ...this.state,
                [e.currentTarget.id]: numVal,
            });
        } else {
            this.setState({ ...this.state, [e.currentTarget.id]: nValue });
        }

        this.setState({ isChanged: true });
        if (this.props.setIsChanged) {
            this.props.setIsChanged(true)
        }
        this.hideErrorMessage(targetId);
    }

    @Bind()
    private handleOtherOptionsSelect(
        section: string,
        e: ChangeEvent<HTMLInputElement>
    ): void {
        const nValue = e.currentTarget.value;
        this.setState({ ...this.state, [section]: nValue });
    }

    @Bind()
    private changeCheckbox(e: ChangeEvent<HTMLInputElement>): void {
        switch (e.currentTarget.id) {
            case "isYardageAddOneExtra": {
                this.setState({
                    isYardageAddOneExtra: !this.state.isYardageAddOneExtra,
                });
                break;
            }
            case "isYardageAddExcess": {
                this.setState({
                    isYardageAddExcess: !this.state.isYardageAddExcess,
                });
                break;
            }
        }
        this.setState({ isChanged: true });
        if (this.props.setIsChanged) {
            this.props.setIsChanged(true)
        }
        this.hideErrorMessage(e.currentTarget.id);
    }

    private validateWorkroomSettings() {
        let errorMessages = []
        
        const hems_and_folds_obj = {
            ['topHemFabricAllowance']: { 
                val: this.state.topHemFabricAllowance, 
                fieldName: 'Top Hem: Total Allowance',
                allowance: {key: 'topHemFabricAllowance' ,checkCase: false, val: undefined} },
            ['bottomNoSkirtHemFinishedSize']: { 
                val: this.state.bottomNoSkirtHemFinishedSize, 
                fieldName: 'Bottom Hem (no skirt): Finished Size',
                allowance: {key: 'bottomNoSkirtHemAllowance' ,checkCase: true, val: this.state.bottomNoSkirtHemAllowance, name: 'Bottom no Skirt hem Allowance' } },
            ['bottomNoSkirtHemAllowance']: { 
                val: this.state.bottomNoSkirtHemAllowance,
                fieldName: 'Bottom Hem (no skirt): Total Allowance',
                allowance: {key: 'bottomNoSkirtHemAllowance' ,checkCase: false, val: undefined} },
            ['bottomWithSkirtHemFinishedSize']: { 
                val: this.state.bottomWithSkirtHemFinishedSize,
                fieldName: 'Bottom Hem with Skirt: Finished Size',
                allowance: {key: 'bottomWithSkirtHemAllowance' ,checkCase: true, val: this.state.bottomWithSkirtHemAllowance, name: 'Bottom with Skirt hem Allowance'}},
            ['bottomWithSkirtHemAllowance']: { 
                val: this.state.bottomWithSkirtHemAllowance, 
                fieldName: 'Bottom Hem with Skirt: Total Allowance',
                allowance: {key: 'bottomWithSkirtHemAllowance' ,checkCase: false, val: undefined} },
            ['sideHemFinishedSize']: { 
                val: this.state.sideHemFinishedSize, 
                fieldName: 'Side Hems: Finished Size',
                allowance: {key: 'sideHemFabricAllowance' ,checkCase: true, val: this.state.sideHemFabricAllowance, name: 'Side Hems Allowance'}},
            ['sideHemFabricAllowance']: { 
                val: this.state.sideHemFabricAllowance, 
                fieldName: 'Side Hems: Total Allowance',
                allowance: {key: 'sideHemFabricAllowance' ,checkCase: false, val: undefined} },
            ['foldsFinishedSize']: { 
                val: this.state.foldsFinishedSize, 
                fieldName: 'Folds: Finished Size',
                allowance: {key: '' ,checkCase: false, val: undefined, name: 'Folds Allowance'}},
        }
        for (const [key,obj] of Object.entries(hems_and_folds_obj)) {
            const fieldName = obj.fieldName
            if (obj && obj.val !== undefined && obj.val <= 0) {
                errorMessages.push({
                    table: 'Hems & Folds',
                    message: `${fieldName} must be greater than zero.`,
                    key: key,
                    allowanceKey: obj.allowance ? obj.allowance.key : '' 
                })
            } else if (
                (obj && obj.val !== undefined && obj.allowance && obj.allowance.val !== undefined && obj.val > obj.allowance.val) ||
                (obj && obj.val !== undefined && obj.allowance && obj.allowance.val === undefined && obj.allowance.checkCase)
            ) {
                errorMessages.push({
                    table: 'Hems & Folds',
                    message: `${fieldName} cannot exceed Total Allowance.`,
                    key: key,
                    allowanceKey: obj.allowance ? obj.allowance.key : '' 
                })
            }
        }

        const valance_and_returns_obj = {
            ['frontValanceLength']: { 
                val: this.state.frontValanceLength, 
                fieldName: 'Front Valance: Length',
                allowance: {key: ''} },
            ['frontValanceVerticalAllowance']: { 
                val: this.state.frontValanceVerticalAllowance, 
                fieldName: 'Front Valance: Vertical Allowance',
                allowance: {key: 'frontValanceVerticalAllowance'} },
            ['frontValanceHorizontalAllowance']: { 
                val: this.state.frontValanceHorizontalAllowance, 
                fieldName: 'Front Valance: Horizontal Allowance',
                allowance: {key: 'frontValanceHorizontalAllowance'}},
            ['backValanceLength']: { 
                val: this.state.backValanceLength, 
                fieldName: 'Back Valance: Length',
                allowance: {key: ''} },
            ['backValanceVerticalAllowance']: { 
                val: this.state.backValanceVerticalAllowance, 
                fieldName: 'Back Valance: Vertical Allowance',
                allowance: {key: 'backValanceVerticalAllowance'} },
            ['backValanceHorizontalAllowance']: { 
                val: this.state.backValanceHorizontalAllowance, 
                fieldName: 'Back Valance: Horizontal Allowance',
                allowance: {key: 'backValanceHorizontalAllowance'} },
            ['sideFlapsLength']: { 
                val: this.state.sideFlapsLength, 
                fieldName: 'Side Flaps: Length',
                allowance: {key: ''} },
            ['sideFlapsVerticalAllowance']: { 
                val: this.state.sideFlapsVerticalAllowance, 
                fieldName: 'Side Flaps: Vertical Allowance',
                allowance: {key: 'sideFlapsVerticalAllowance'} },
            ['sideFlapsHorizontalAllowance']: { 
                val: this.state.sideFlapsHorizontalAllowance, 
                fieldName: 'Side Flaps: Horizontal Allowance',
                allowance: {key: 'sideFlapsHorizontalAllowance'} },
        }

        for (const [key,obj] of Object.entries(valance_and_returns_obj)) {
            const fieldName = obj.fieldName
            if (obj && obj.val !== undefined && obj.val <= 0) {
                errorMessages.push({
                    table: 'Valance & Returns',
                    message: `${fieldName} must be greater than zero.`,
                    key: key,
                    allowanceKey: obj.allowance ? obj.allowance.key : ''
                })
            }
        }

        const linings_and_embellishments_obj = {
            ['liningVerticalAllowance']: { 
                val: this.state.liningVerticalAllowance, 
                fieldName: 'Lining Vertical Allowance',
                group: 'linings', allowance: undefined },
            ['Lining_Horizontal_Allowance']: { 
                val: undefined, 
                fieldName: 'Lining Horizontal Allowance',
                group: 'linings', allowance: undefined },
            ['interliningVerticalAllowance']: { 
                val: this.state.interliningVerticalAllowance, 
                fieldName: 'Interlining Vertical Allowance',
                group: 'linings', allowance: undefined },
            ['Interlining_Horizontal_Allowance']: { 
                val: undefined, 
                fieldName: 'Interlining Horizontal Allowance',
                group: 'linings', allowance: undefined },
            ['trimVerticalAllowance']: { 
                val: this.state.trimVerticalAllowance, 
                fieldName: 'Trim Vertical Allowance',
                group: 'embellishments', allowance: undefined },
            ['trimHorizontalAllowance']: { 
                val: this.state.trimHorizontalAllowance, 
                fieldName: 'Trim Horizontal Allowance',
                group: 'embellishments', allowance: undefined },
        }

        for (const [key,obj] of Object.entries(linings_and_embellishments_obj)) {
            const fieldName = obj.fieldName
            if (obj && obj.group !== 'linings' && obj.val !== undefined && obj.val < 0) {
                errorMessages.push({
                    table: 'Linings & embellishments cut lengths',
                    message: `${fieldName} must be 0 or greater.`,
                    key: key,
                    allowanceKey: ''
                })
            }
        }

        if (this.state.isYardageAddExcess) {
            if (this.state.addExcessValue === undefined) {
                errorMessages.push({
                    table: 'Main Fabric Additional Allowance',
                    message: `Please specify the amount of excess yardage for main fabric.`,
                    key: 'addExcessValue',
                    allowanceKey: '',
                })
            } else if (this.state.addExcessValue <= 0) {
                errorMessages.push({
                    table: 'Main Fabric Additional Allowance',
                    message: `Main Fabric Additional Allowance amount must be greater than zero.`,
                    key: 'addExcessValue',
                    allowanceKey: '',
                })
            }

            if (
                this.state.addExcessUnit === undefined ||
                this.state.addExcessUnit === "" ||
                this.state.addExcessUnit === "select unit"
            ) {
                errorMessages.push({
                    table: 'Main Fabric Additional Allowance',
                    message: `Please specify the unit of excess yardage for main fabric.`,
                    key: 'addExcessUnit',
                    allowanceKey: '',
                })
            }
        }

        return errorMessages;
    }

    @Bind()
    //ts-ignore since this is used in another file
    //@ts-ignore
    private async saveCurrentSetting(): Promise<boolean> {
        if (this.state.currentSettingID === 0) {

            this.throwCalcError([{ table: '', message: 'Please select a default.', key: '', allowanceKey: '' }]);
            return false;
        }

        const validationResult = this.validateWorkroomSettings();
        if (validationResult && validationResult.length > 0) {
            this.throwCalcError(validationResult);
            const doc = document.getElementById(validationResult[0].key)
            if (doc) {
                doc.focus()
            }
            return false;
        }

        const res = await editWorkroomSetting({
            id: this.state.defaultSettingList[0].key, // Full Length
            presetIsYardageAddOneExtra: this.state.isYardageAddOneExtra,
            presetIsYardageAddExcess: this.state.isYardageAddExcess,
            presetAddExcessValue: this.state.addExcessValue,
            presetAddExcessUnit: this.state.addExcessUnit,

            fabricPurchaser: this.state.fabricPurchaser,
            embellishmentsPurchaser: this.state.embellishmentsPurchaser,
            liningsPurchaser: this.state.liningsPurchaser,

            presetEmbellishmentJson: {
                trimVertical: this.state.trimVerticalAllowance,
                trimHorizontal: this.state.trimHorizontalAllowance,
            },
            presetHemsAndFoldsJson: {
                topHemFinishedSize: 'N/A',
                topHemFabricAllowance: this.state.topHemFabricAllowance,
                bottomNoSkirtHemFinishedSize: this.state.bottomNoSkirtHemFinishedSize,
                bottomNoSkirtHemAllowance: this.state.bottomNoSkirtHemAllowance,
                bottomWithSkirtHemFinishedSize: this.state.bottomWithSkirtHemFinishedSize,
                bottomWithSkirtHemAllowance: this.state.bottomWithSkirtHemAllowance,
                sideHemFinishedSize: this.state.sideHemFinishedSize,
                sideHemFabricAllowance: this.state.sideHemFabricAllowance,
                foldsFinishedSize: this.state.foldsFinishedSize,
                foldsAllowance: this.state.foldsFinishedSize !== undefined ? this.state.foldsFinishedSize * 2 : undefined,
                permanentFoldsFunctional: this.state.permanentFoldsFunctional,
                permanentFoldsFaux: this.state.permanentFoldsFaux,
            },
            presetValanceAndReturnsJson: {
                frontValanceLength: this.state.frontValanceLength,
                frontValanceVerticalAllowance: this.state.frontValanceVerticalAllowance,
                frontValanceHorizontalAllowance: this.state.frontValanceHorizontalAllowance,
                backValanceLength: this.state.backValanceLength,
                backValanceVerticalAllowance: this.state.backValanceVerticalAllowance,
                backValanceHorizontalAllowance: this.state.backValanceHorizontalAllowance,
                sideFlapsLength: this.state.sideFlapsLength,
                sideFlapsVerticalAllowance: this.state.sideFlapsVerticalAllowance,
                sideFlapsHorizontalAllowance: this.state.sideFlapsHorizontalAllowance,
            },
            presetLiningsJson: {
                liningVerticalAllowance: this.state.liningVerticalAllowance,
                interliningVerticalAllowance: this.state.interliningVerticalAllowance,
            },
        })

        if (res.error) {
            this.throwCalcError([{ table: '' , message: res.message, key: '', allowanceKey: '' }]);
            return false;
        }
        return true;
    }
    @Bind()
    private handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void {
        const { key } = event;
        // Check if the pressed key is "-" or any other unwanted characters
        if (key === "-" || key === "+") {
            event.preventDefault();
        }
    }

    @Bind()
    private throwCalcError(errorMsg: ErrorMessagesArray[]) {
        this.setState({ formError: true, formErrorMessage: errorMsg });
    }

    @Bind()
    private hideErrorMessage(name: string): void {
        const filteredArray = this.state.formErrorMessage && this.state.formErrorMessage.length > 0 ? this.state.formErrorMessage.filter(i => {
            if (name.includes('Allowance')) {
                return i.allowanceKey !== name
            }
            return i.key !== name
        }) : []

        this.setState({ formError: true, formErrorMessage: filteredArray });
    }

    @Bind()
    private async submit(e: FormEvent<HTMLFormElement>): Promise<void> {
        //do nothing
    }

    private handleSelectAddExcessUnit = (
        value: string,
        name?: string
    ): void => {
        this.setState({ addExcessUnit: value, isChanged: true });
        if (this.props.setIsChanged) {
            this.props.setIsChanged(true)
        }
    };

    private async onDefaultNameChanged(
        value: number,
        label: string
    ): Promise<void> {
        const res = await getWorkroomSetting({ id: value })

        if (res.error) {
            throw new Error(res.message);
        }
        const defaultSettingDB = res;
        
        this.setState(prev => {
            return {
                currentSettingID: value,
                currentSettingName: label,

                isYardageAddOneExtra:
                    defaultSettingDB.presetIsYardageAddOneExtra !== undefined
                        ? defaultSettingDB.presetIsYardageAddOneExtra
                        : true,
                isYardageAddExcess:
                    defaultSettingDB.presetIsYardageAddExcess !== undefined
                        ? defaultSettingDB.presetIsYardageAddExcess
                        : false,

                addExcessValue: defaultSettingDB.presetAddExcessValue,
                addExcessUnit:
                    defaultSettingDB.presetAddExcessUnit || "select unit",

                fabricPurchaser: defaultSettingDB.fabricPurchaser,
                embellishmentsPurchaser: defaultSettingDB.embellishmentsPurchaser,
                liningsPurchaser: defaultSettingDB.liningsPurchaser,

                // HEMS & FOLDS
                topHemFabricAllowance: 
                    defaultSettingDB.presetHemsAndFoldsJson && defaultSettingDB.presetHemsAndFoldsJson.topHemFabricAllowance !== undefined 
                        ? defaultSettingDB.presetHemsAndFoldsJson.topHemFabricAllowance 
                        : undefined,
                topHemFinishedSize: 
                    defaultSettingDB.presetHemsAndFoldsJson && defaultSettingDB.presetHemsAndFoldsJson.topHemFinishedSize 
                        ? defaultSettingDB.presetHemsAndFoldsJson.topHemFinishedSize 
                        : undefined,
                bottomNoSkirtHemFinishedSize: 
                    defaultSettingDB.presetHemsAndFoldsJson && defaultSettingDB.presetHemsAndFoldsJson.bottomNoSkirtHemFinishedSize 
                        ? defaultSettingDB.presetHemsAndFoldsJson.bottomNoSkirtHemFinishedSize 
                        : undefined,
                bottomNoSkirtHemAllowance: 
                    defaultSettingDB.presetHemsAndFoldsJson && defaultSettingDB.presetHemsAndFoldsJson.bottomNoSkirtHemAllowance !== undefined
                        ? defaultSettingDB.presetHemsAndFoldsJson.bottomNoSkirtHemAllowance 
                        : undefined,
                bottomWithSkirtHemFinishedSize: 
                    defaultSettingDB.presetHemsAndFoldsJson && defaultSettingDB.presetHemsAndFoldsJson.bottomWithSkirtHemFinishedSize 
                        ? defaultSettingDB.presetHemsAndFoldsJson.bottomWithSkirtHemFinishedSize 
                        : undefined,
                bottomWithSkirtHemAllowance: 
                    defaultSettingDB.presetHemsAndFoldsJson && defaultSettingDB.presetHemsAndFoldsJson.bottomWithSkirtHemAllowance !== undefined
                        ? defaultSettingDB.presetHemsAndFoldsJson.bottomWithSkirtHemAllowance 
                        : undefined,

                sideHemFinishedSize: 
                    defaultSettingDB.presetHemsAndFoldsJson && defaultSettingDB.presetHemsAndFoldsJson.sideHemFinishedSize 
                        ? defaultSettingDB.presetHemsAndFoldsJson.sideHemFinishedSize 
                        : undefined,
                sideHemFabricAllowance: 
                    defaultSettingDB.presetHemsAndFoldsJson && defaultSettingDB.presetHemsAndFoldsJson.sideHemFabricAllowance !== undefined
                        ? defaultSettingDB.presetHemsAndFoldsJson.sideHemFabricAllowance 
                        : undefined,
                    
                foldsFinishedSize:
                    defaultSettingDB.presetHemsAndFoldsJson && defaultSettingDB.presetHemsAndFoldsJson.foldsFinishedSize 
                        ? defaultSettingDB.presetHemsAndFoldsJson.foldsFinishedSize 
                        : undefined,
                foldsAllowance: 
                    defaultSettingDB.presetHemsAndFoldsJson && defaultSettingDB.presetHemsAndFoldsJson.foldsAllowance !== undefined
                        ? defaultSettingDB.presetHemsAndFoldsJson.foldsAllowance 
                        : undefined,

                permanentFoldsFunctional: 
                    defaultSettingDB.presetHemsAndFoldsJson && defaultSettingDB.presetHemsAndFoldsJson.permanentFoldsFunctional !== undefined
                        ? defaultSettingDB.presetHemsAndFoldsJson.permanentFoldsFunctional 
                        : undefined,
                permanentFoldsFaux: 
                    defaultSettingDB.presetHemsAndFoldsJson && defaultSettingDB.presetHemsAndFoldsJson.permanentFoldsFaux !== undefined
                        ? defaultSettingDB.presetHemsAndFoldsJson.permanentFoldsFaux 
                        : undefined,

                // VALANCE & RETURNS
                frontValanceLength: 
                    defaultSettingDB.presetValanceAndReturnsJson && defaultSettingDB.presetValanceAndReturnsJson.frontValanceLength 
                        ? defaultSettingDB.presetValanceAndReturnsJson.frontValanceLength 
                        : undefined,
                frontValanceHorizontalAllowance: 
                    defaultSettingDB.presetValanceAndReturnsJson && defaultSettingDB.presetValanceAndReturnsJson.frontValanceHorizontalAllowance !== undefined
                        ? defaultSettingDB.presetValanceAndReturnsJson.frontValanceHorizontalAllowance 
                        : undefined,
                frontValanceVerticalAllowance: 
                    defaultSettingDB.presetValanceAndReturnsJson && defaultSettingDB.presetValanceAndReturnsJson.frontValanceVerticalAllowance !== undefined
                        ? defaultSettingDB.presetValanceAndReturnsJson.frontValanceVerticalAllowance 
                        : undefined,

                backValanceLength: 
                    defaultSettingDB.presetValanceAndReturnsJson && defaultSettingDB.presetValanceAndReturnsJson.backValanceLength 
                        ? defaultSettingDB.presetValanceAndReturnsJson.backValanceLength 
                        : undefined,
                backValanceHorizontalAllowance: 
                    defaultSettingDB.presetValanceAndReturnsJson && defaultSettingDB.presetValanceAndReturnsJson.backValanceHorizontalAllowance !== undefined
                        ? defaultSettingDB.presetValanceAndReturnsJson.backValanceHorizontalAllowance 
                        : undefined,
                backValanceVerticalAllowance: 
                    defaultSettingDB.presetValanceAndReturnsJson && defaultSettingDB.presetValanceAndReturnsJson.backValanceVerticalAllowance !== undefined
                        ? defaultSettingDB.presetValanceAndReturnsJson.backValanceVerticalAllowance 
                        : undefined,
                    
                sideFlapsLength: 
                    defaultSettingDB.presetValanceAndReturnsJson && defaultSettingDB.presetValanceAndReturnsJson.sideFlapsLength 
                        ? defaultSettingDB.presetValanceAndReturnsJson.sideFlapsLength 
                        : undefined,
                sideFlapsHorizontalAllowance: 
                    defaultSettingDB.presetValanceAndReturnsJson && defaultSettingDB.presetValanceAndReturnsJson.sideFlapsHorizontalAllowance !== undefined
                        ? defaultSettingDB.presetValanceAndReturnsJson.sideFlapsHorizontalAllowance 
                        : undefined,
                sideFlapsVerticalAllowance: 
                    defaultSettingDB.presetValanceAndReturnsJson && defaultSettingDB.presetValanceAndReturnsJson.sideFlapsVerticalAllowance !== undefined
                        ? defaultSettingDB.presetValanceAndReturnsJson.sideFlapsVerticalAllowance 
                        : undefined,

                // LININGS & EMBELLISHMENTS CUT LENGTHS
                liningVerticalAllowance: 
                    defaultSettingDB.presetLiningsJson && defaultSettingDB.presetLiningsJson.liningVerticalAllowance !== undefined 
                        ? defaultSettingDB.presetLiningsJson.liningVerticalAllowance 
                        : undefined,
                interliningVerticalAllowance: 
                    defaultSettingDB.presetLiningsJson && defaultSettingDB.presetLiningsJson.interliningVerticalAllowance !== undefined
                        ? defaultSettingDB.presetLiningsJson.interliningVerticalAllowance 
                        : undefined,
                trimVerticalAllowance: 
                    defaultSettingDB.presetEmbellishmentJson && defaultSettingDB.presetEmbellishmentJson.trimVertical !== undefined
                        ? defaultSettingDB.presetEmbellishmentJson.trimVertical
                        : undefined,
                trimHorizontalAllowance:
                    defaultSettingDB.presetEmbellishmentJson && defaultSettingDB.presetEmbellishmentJson.trimHorizontal !== undefined
                        ? defaultSettingDB.presetEmbellishmentJson.trimHorizontal
                        : undefined,
            }
        });
    }
}

export default connect(null)(ConstructionSettings);