import axiosInstance from "./axios-instance";

export const storeFile = async (storeId: string, fileName: string, buffer: Buffer, fileStoreUrl: string) => {
    try {
        const response = await axiosInstance.post<any>('/file-store/storeFile', { 
            storeId, 
            fileName,
            pdfBuffer: buffer,
            fileStoreUrl,    
         })
        return response.data;
    } catch (error) {
        console.error("Error with call lambda: ", error);
        throw error;
    }
}

export const retrieveFile = async (storeId: string, fileName: string, fileStoreUrl: string) => {
    try {
        const response = await axiosInstance.post<any>('/file-store/retrieveFile', { 
            storeId, 
            fileName,
            fileStoreUrl,    
         })
        return response.data;
    } catch(error){
        throw error;
    }
}